@import "src/styles/variables"
@import "src/styles/breakpoints"

.notificationInner
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.notificationText
  display: flex
  align-items: center
  color: var(--wt-blue3-dt-white2)
  text-shadow: var(--text-shadow-2)
  font-size: 1.143rem
  font-weight: bold
  svg
    margin-right: 20px

.buttonStock
  max-width: 195px
  margin-right: 30px

.notificationClose
  width: 36px
  min-width: 36px
  height: 36px
  svg
    width: 17px
    height: auto
    > path
      fill: var(--wt-blue3-dt-blue1)
    margin-left: auto
    margin-right: auto

.notificationButtons
  display: flex
  align-items: center

.notificationCloseMobile
  display: none


@include media('<=Lphone')
  .notificationInner
    flex-direction: column
    padding-left: 0
  .notificationClose
    display: none
  .notificationCloseMobile
    display: block
    margin-left: 20px
  .notificationText
    align-items: flex-start
    p
      margin-top: 0
      margin-left: 20px

@include media('<=phone')
  .notificationText
    > svg
      display: none
    p
      margin-left: 0
  .notificationCloseMobile
    margin-left: 15px