.notificationInner
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.notificationText
  display: flex
  align-items: center
  color: var(--wt-blue3-dt-white2)
  text-shadow: var(--text-shadow-2)
  font-size: 1.143rem
  font-weight: bold
  svg
    margin-right: 20px

.notificationButtons
  margin-left: 15px
  display: flex
  align-items: center

.notificationClose
  width: 36px
  height: 36px
  svg
    width: 17px
    height: auto
    > path
      fill: var(--wt-blue3-dt-blue1)