@import "src/styles/breakpoints"

.notificationInner
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.notificationText
  display: flex
  align-items: center
  color: var(--wt-blue3-dt-white2)
  text-shadow: var(--text-shadow-2)
  font-size: 1.143rem
  font-weight: bold
  svg
    margin-right: 20px

.notificationClose
  width: 36px
  min-width: 36px
  height: 36px
  margin-left: 15px
  svg
    width: 17px
    height: auto
    > path
      fill: var(--wt-blue3-dt-blue1)

@include media('<=phone')
  .notificationInner
    flex-direction: column
  .notificationText
    text-align: center
    svg
      display: none
  .notificationClose
    margin-left: 0
    margin-top: 10px
  .button
    max-width: 100%